<template>
  <div style="min-height: 100vh;">
    <div class="header">
      <titleLink title="安全防护信息详情" :onClick="onClickBack"></titleLink>
      <div style="padding:.2rem .2rem 3rem .2rem;height:95vh;overflow-y:hidden;overflow:scroll">
        <card style="margin-top:30px">
          <template #content>
            <van-row style="text-align:center;">
              <span class="title_text">{{notification.notification.title}}</span>
            </van-row>
            <van-row style="margin-top:30px">
              <div v-html="HTMLDecode(notification.notification.content)" style="font-size: 12px;"></div>
            </van-row>
            <van-row class="title_info_text">
              <van-col span="12" style="text-align:left">
                {{notification.notification.author}}
              </van-col>
              <van-col span="12" style="text-align:right">
                {{notification.notification.created_at}}
              </van-col>
            </van-row>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import {mapGetters} from 'vuex'
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    card, titleLink
  },
  computed: {
    ...mapGetters('notification', {
      notification: 'getCurrentNotification'
    })
  },
  created() {
    this.$store.dispatch('notification/getNotificationById', this.$route.params.id)
  },
  methods: {
    onClickBack() {
      this.$router.push({path: '/anquan/main'})
    },
    //html反转义
    HTMLDecode(text) {
        var reg = /<[^>]+>/g;
        if (reg.test(text)) {
            return text;
        }
        var temp = document.createElement('div');
        temp.innerHTML = text;
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    },
  }
}
</script>

<style>
.title_text {
  font-size: 16px;
  font-weight: bold;
}
.title_info_text {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  color: #999999;
}
.content_text {
  color: #666666;
  font-size: 14px;
}
</style>